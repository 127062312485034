export const status = [
  {
    title: 'Active Alerts',
    key: 'alerts',
    value: null,
    color: '#B35792',
    icon: 'triangle-exclamation'
  },
  {
    title: 'Devices Online',
    key: 'online',
    value: null,
    color: '#7857B3',
    icon: 'hospital'
  },
  {
    title: 'Accuracy',
    key: 'aiAccuracy',
    value: null,
    color: '#00B79E',
    icon: 'user'
  }
]

export const alerts = [
  {
    key: 'total_alerts',
    title: 'Total Alerts',
    link: 'TotalAlerts',
    value: null,
    legends: [
      {
        name: 'All 4 Vitals',
        value: null,
        status: 0,
        key: 4
      },
      {
        name: '3 Vitals',
        value: null,
        status: 0,
        key: 3
      },
      {
        name: '2 Vitals',
        value: null,
        status: 0,
        key: 2
      },
      {
        name: '1 Vital',
        value: null,
        status: 0,
        key: 1
      }
    ]
  },
  {
    title: 'Total Predicted Alerts',
    value: 0,
    key: 'total_predicted_alerts',
    legends: [
      {
        name: 'Predicted',
        key: 'predictive',
        value: 0,
        status: 0
      },
      {
        name: 'False Positive',
        key: 'falsePositive',
        value: 0,
        status: 0
      },
      {
        name: 'Unpredicted',
        key: 'unPredictive',
        value: 0,
        status: 0
      }
    ]
  },
  {
    title: 'High Frequency Alerts',
    value: 12,
    key: 'total_high_frequency_alerts',
    legends: [
      {
        name: '>3 alerts',
        value: 6,
        status: 0
      },
      {
        name: '3 Alerts',
        value: 7,
        status: 0
      },
      {
        name: '2 Alerts',
        value: 3,
        status: 1
      }
    ]
  }
]

export const tableHeaders = {
  // name: 'D.Name',
  // age: 'Age',
  // location: 'Location',
  deviceId: 'Device Id',
  needs_attention: 'Status',
  temperature: 'Temperature',
  butane: 'Butane',
  sulphurDioxide: 'Sulphur Dioxide',
  carbonDioxide: 'Carbon Dioxide',
  datetime: 'Last Updated'
}
export const deployedTableHeader = {
  deviceId: 'Device Id',
  deviceName: 'Device Name',
  deployedBy: 'Deployed By',
  deployedStatus: 'Deployed Status'
}

export const deviceTableHeader = {
  deviceId: 'Device Id',
  deviceName: 'Device Name'
}
