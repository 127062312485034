<template>
  <!-- ============================= device section ========================================= -->
  <div class="tabs-details" :class="{ 'border-grey': !fullScreen }">
    <div class="ph2 mt2 display-flex justify-between align-center-mb pos-rel border-bottom">
    <div class="display-flex align-center-mb">
        <span class="pa1-2 f1-5" :v-if="topTableHeading">{{topTableHeading}}</span>
    </div>
    <!-- ======= Ruleset deployed device table section ====== -->
      <div class="display-flex align-center none-mb">
        <bh-button
          class="display-flex align-center mb2 mr1"
          btnClass="bg-violet text-white border-none br0-5 f1-5 pa1"
          :disabled="isRemoveBtnDisabled"
          @output="removeSelectedRow"
          >
          Remove Selected Device
        </bh-button>
        <div class="display-flex" v-if="isShow">
        <bh-button @output="openModal" class="display-flex align-center mb2" btnClass="bg-violet text-white border-none br0-5 f1-5 pa1">
          <bh-icon icon="plus" class="mr2" />
          <span class="mt0-2">Add New Devices</span>
        </bh-button>
        </div>
      </div>
    </div>
    <!-- ======= deployed device table ====== -->
    <bh-table
      sticky
      class="datatable-container w100p overflow-auto maxh65 text-no-wrap box-shadow box-shadow-light-dk maxh40-mb"
      headerClass="w25p-mb bg-violet-light"
      enableCheckbox
      stickyColMob="name"
      :numberOfColumnsMob="1"
      :rows="deployedDeviceList"
      :configDeviceList="true"
      :headers="rulesetDeviceHeaders"
      :tableLength="deployedDeviceList.length"
      :enableSearch ="false"
      @onRowSelect="onRowSelect($event, deployedDeviceList,'deployedDeviceList')"
      @onAllRowSelect="onRowSelectAll({...$event, array:deployedDeviceList, table: 'deployedDeviceList' })"
    >
    </bh-table>
    <!-- ============================= Device list model overlay ========================================= -->
    <bh-modal-overlay :showModal="showModal" @modal-close="openModal">
    <div class="w40vw-dk text-center bg-white w98vw">
      <div class="display-flex justify-between align-center f2 pv2 ph2">
        <div>
         <span>Add Devices</span>
        </div>
        <bh-select v-if="!isShow"
          class="pa0-5 br0-5 border-grey text-left f1-5"
          placeholder="Select AI models"
          classes="z-999999 w30"
          :options="getDevicesOptions()"
        />
      <!-- <bh-select
        class="pa0-5 br0-5 border-grey text-left f1-5"
        classes="w10vw h40p z-999999"
        :value="scheduleList"
        placeholder="Schedule Time"
        :options="getSchedulingOptions()"
        @output="scheduleTime"
      /> -->
      </div>
      <div class="w100p justify-between w100p pa2-dk">
        <div class="text-left pa1-mb">
      <!-- ======= list of devices table ====== -->
      <!--  Removed from the list of devices table :isHeaderCheckboxDisabled="isCheckboxDisabled" -->
      <bh-table
      sticky
      class="datatable-container w100p overflow-auto maxh65 text-no-wrap box-shadow box-shadow-light-dk maxh40-mb"
      headerClass="w25p-mb bg-violet-light"
      enableCheckbox
      stickyColMob="name"
      :numberOfColumnsMob="1"
      :configDeviceList="true"
      :rows="deviceList"
      :headers="deviceListHeaders"
      :tableLength="deviceList.length"
      :enableSearch ="false"
      @onRowSelect="onRowSelect($event, deviceList,'deviceList')"
      @onAllRowSelect="onRowSelectAll({...$event, array:deviceList, table: 'deviceList' })"
    >
      </bh-table>
        </div>
      </div>
      <!-- ======= actions table ====== -->
      <div class="display-flex justify-around">
      <bh-button
        btnClass="bg-violet text-white border-none br0-5 mt3 f1-5 pa1 mb2 w25p"
        @output="closeModal"
      >
        Cancel
      </bh-button>
      <bh-button v-if="!isShow"
       :disabled="!isDeployBtnDisabled"
       btnClass="bg-violet text-white border-none br0-5 mt3 f1-5 pa1 mb2 w30p"
       @output="onSelectActivatedModel"
        >
        <span>Activated Model</span>
      </bh-button>
      <bh-button
       btnClass="bg-violet text-white border-none br0-5 mt3 f1-5 pa1 mb2 w30p"
       isBtnIconVisisble
       :disabled="!isDeployBtnDisabled"
       @showBtnIcon.stop="openDateRangeModel"
       @output="deployDevices"
        >
       <!-- :disabled="isCheckboxDisabled || !isDeployBtnDisabled" -->
      <span>Deploy</span>
      </bh-button>
      <!-- ======= date range ====== -->
      <div :class="!isShowDataPicker ? 'hideDatePicker' : 'showDatePicker'">
      <bh-date-time-picker
        ref="dateTimePicker"
        :dateRange="dateRange"
        @closeDateTime="closeDateTime"
      />
      </div>
      </div>
    </div>
    </bh-modal-overlay>
    <!--- ============== Date Range Model Overlay ======= ---------->
    <div class="model-overlay">
    <bh-modal-overlay :showModal="showDateRangeModal" @modal-close="openDateRangeModel">
      <div class="model-container f1-5 h30 w45 br0-5" :data="scheduleList">
        <div class="models-header display-flex flex-row justify-between mh2-1 align-center justify-around h5 br-top1-2 f1-9">
          Schedule Send
        <bh-icon icon="cross" class="pointer" @output.self="openDateRangeModel"/>
        </div>
        <v-list rounded>
      <v-list-item-group color="primary" class="model-group-list maxh40 overflow-auto br1-4">
        <v-list-item
          v-for="data in scheduleList" :key="data.text">
          <v-list-item-content class="content-list" @click="onScheduleTimeList(data.text)">
            {{data.text}}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    </div>
    </bh-modal-overlay>
  </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { deployedTableHeader, deviceTableHeader } from '@/constants/dashboard'
import { getDeviceList, getDeployedDeviceList } from '@/services/configurations'

export default {
  name: 'bh-device-list',
  props: {
    selectedTab: {},
    topTableHeading: '',
    deployAIDevice: '',
    dialogOpened: false,
    dataList: {
      type: Array,
      default() {
        return []
      }
    },
    isInvalidCustomDate: false
  },
  data () {
    return {
      fullScreen: false,
      showModal: false,
      showDateRangeModal: false,
      rulesetDeviceHeaders: deployedTableHeader,
      deviceListHeaders: deviceTableHeader,
      deployedDeviceList: [],
      deviceList: [],
      isRemoveBtnDisabled: {
        type: Boolean,
        default: true
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      isDeployBtnDisabled: false,
      dateRange: {},
      // isCheckboxDisabled: false,
      isShow: false,
      scheduleList: [
        { text: 'Tommorow Morning  Apr 28, 8:00 AM', value: 0 },
        { text: 'Tommorow Afternoon  Apr 28, 4:00 PM', value: 1 },
        { text: 'Tommorow Morning  May 3, 8:00 AM', value: 2 },
        { text: 'Pick date & time', value: 3 }
      ],
      isShowDataPicker: false
    }
  },
  computed: {
    ...mapGetters('performance', ['getDeployDialogStatus'])
  },
  async mounted() {
    const routeName = this.$router.history.current.name
    if (routeName === 'Performance') {
      this.isShow = false
    } else {
      this.isShow = true
    }
    const deviceList = await getDeviceList()
    const deployedDeviceList = await getDeployedDeviceList('Default Settings')
    this.deviceList = deviceList
    this.deployedDeviceList = deployedDeviceList
    if (this.deployAIDevice) {
      this.deployAIDevice.forEach((ele) => {
        this.deployedDeviceList.push({
          checked: false,
          deviceId: {
            value: ele.deviceId.value
          },
          deviceName: {
            value: ele.deviceName.value
          },
          deployedBy: { value: 'Marrie' },
          deployedStatus: { value: 'Success' }
        })
      })
    }
  },
  watch: {
    selectedTab() {
      return this.onTabChange()
    },
    getDeployDialogStatus() {
      if (this.getDeployDialogStatus) {
        this.openModal()
      }
    }
  },
  methods: {
    ...mapActions('performance', ['setDeployDialogStatus']),
    async onTabChange() {
      this.deployedDeviceList = await getDeployedDeviceList(this.selectedTab.name)
    },

    /**
     * toggleFullScreen
     */
    toggleFullScreen() {
      this.fullScreen = !this.fullScreen
    },

    /**
     * close add new device model
     */
    closeModal() {
      this.setDeployDialogStatus(false)
      this.showModal = !this.showModal
    },

    /**
     * openModal
     * @description : on click open model box
     */
    openModal() {
      // disable checkbox if device is alerady deployed
      // this.deployedDeviceList.forEach((list) => {
      //   this.deviceList.forEach((ele) => {
      //     if (list.deviceName.value === ele.deviceName.value) {
      //       ele.isDisabled = true
      //     }
      //   })
      // })
      // Disable the Header checkbox
      // const state = this.deviceList.every((device) => {
      //   return device.isDisabled === true
      // })
      // this.isCheckboxDisabled = state
      this.showModal = !this.showModal
    },

    /**
     * deployDevices
     * @description: On click deploy the seleted devices
     */
    deployDevices(event) {
      // this.openModal()
      this.deviceList.forEach((ele) => {
        if (ele.checked) {
          this.deployedDeviceList.push({
            checked: false,
            deviceId: {
              value: ele.deviceId.value
            },
            deviceName: {
              value: ele.deviceName.value
            },
            deployedBy: { value: 'Marrie' },
            deployedStatus: { value: 'Success' }
          })
          ele.checked = false
        }
      })
      this.closeModal()
    },

    /**
   * onRowSelect
   * select each row
   */
    onRowSelect(row, array, table) {
      const index = array.findIndex((x) => x.deviceId.value === row.deviceId.value)
      array[index].checked = row.checked
      if (table === 'deployedDeviceList') {
        this.checkTabRowSelected()
      }
      row.checked === true ? this.isDeployBtnDisabled = true : row.checked === false ? this.isDeployBtnDisabled = false : this.isDeployBtnDisabled
    },

    /**
   * onRowSelectAll
   * header selection
   */
    onRowSelectAll({ state, rows, array, table }) {
      if (state) {
        array = [...rows]
      } else {
        array.forEach((ele) => {
          ele.checked = false
        })
      }
      if (table === 'deployedDeviceList') {
        this.checkTabRowSelected()
      }
    },

    /**
     * checkTabRowSelected
     */
    checkTabRowSelected() {
      if (this.deviceList.length) {
        const state = this.deviceList.some((ele) => {
          ele.checked === true
        })
        if (state) {
          this.isRemoveBtnDisabled = !state
        } else {
          this.isRemoveBtnDisabled = state
        }
      } else {
        this.isRemoveBtnDisabled = true
      }
    },

    /**
     * removeSelectedRow
     * @desc  onClick remove btn remove the selected row
     */
    removeSelectedRow() {
      const filteredData = this.deployedDeviceList.filter((ele) => {
        return !ele.checked
      })
      this.deployedDeviceList.forEach((ele) => {
        if (ele.checked) {
          this.deviceList.forEach((item) => {
            item.isDisabled = false
          })
        }
      })
      this.deployedDeviceList = [...filteredData]
    },

    /**
     * Option for getting list of devices
     */
    getDevicesOptions() {
      console.log(this.dataList)
      return this.dataList.map((device, index) => ({
        label: `${device.name}`,
        value: index
      }))
    },

    /**
     * select Activated model
     */
    onSelectActivatedModel() {
      // activate model
      this.setDeployDialogStatus(false)
      this.deployDevices()
    },

    /**
     * on click close dateTime picker model
     */
    closeDateTime(event) {
      this.isShowDataPicker = event
    },

    /**
     * @method onScheduleTimeList
     * on click list of ScheduleT time make a api call
     */
    onScheduleTimeList(data) {
      if (data) {
        if (data === 'Pick date & time') {
          this.showDateRangeModal = !this.showDateRangeModal
          this.dateRange = {
            minDate: Math.floor(new Date().getTime() / 1000.0),
            maxDate: Math.floor(new Date().getTime() / (1000.0 - 10))
          }
          this.isShowDataPicker = true
          this.$refs['dateTimePicker'].openDateTimePicker()
        } else {
          this.isShowDataPicker = false
          this.showDateRangeModal = !this.showDateRangeModal
          this.showModal = !this.showModal
          this.setDeployDialogStatus(false)
        }
      }
    },

    /**
     * @method: openDateRangeModel
     * on click schedule icon open list of Date Range Model
     */
    openDateRangeModel(data) {
      this.showDateRangeModal = !this.showDateRangeModal
    }
  }
}
</script>
<style scoped>
.hideDatePicker {
  display: none;
}
.showDatePicker {
  display: inline;
}
.model-container {
  background-color: var(--gainsboro-white);
}
.content-list {
  color: var(--text-light-black);
}
</style>
